/* HomePage.css */
.homepage {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
 /* Allow scrolling if content overflows */
  color: white;
  font-family: 'Open Sans', Arial, sans-serif;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;

  z-index: 0;
}

video {
  position: absolute;
  top: -290px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotate(180deg);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.title {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
}

.highlight {
  color: #a4508b;
  background: linear-gradient(90deg, rgba(164,80,139,1) 0%, rgba(106,89,222,1) 50%, rgba(36,198,220,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.learn-more {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #a4508b;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more:hover {
  background-color: #6a59de;
}
