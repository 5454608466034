/* AboutUs.css */
.about-us {
  padding: 50px;
  background: linear-gradient(135deg, #000 25%, #130c2d 100%);
  color: white;
  z-index: 3;
  animation: backgroundShift 5s infinite alternate;
}

@keyframes backgroundShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.about-us-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-title {
  font-size: 4em;
  margin-bottom: 20px;
  animation: fadeInLeft 1s ease-in-out;
  align-self: center;
  color: #6a59de;

}

.about-description {
  font-size: 1.5em;
  line-height: 1.5;
  margin-bottom: 20px;
  animation: fadeInRight 1.5s ease-in-out;
  align-self: center;
  padding-left: 90px;
  padding-right: 90px;
}

.separator-line {
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #a4508b, #6a59de);
  margin: 40px 0; /* Adjust margin as needed */
  animation: expandLine 1s ease-in-out;
}

@keyframes expandLine {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.services {
  width: 100%;
}

.services-title {
  font-size: 4em;
  margin-bottom: 20px;
  text-align: center;
  color: #6a59de;
  animation: fadeInUp 2s ease-in-out;
}

.video-background-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure the video is behind the content */
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  z-index: 2; /* Ensure content is above the video */
}

.service-card {
  color: #fff; /* Navy Blue */
  background-color: transparent; /* White */
  font-size: 1.3em;

  padding: 20px;
  border-radius: 10px;
  width: 20%;
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: fadeIn 2s ease-in-out, bounceIn 2s ease-in-out, float 5s infinite alternate;
  margin: 5px;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  animation: bounceHover 0.6s ease-in-out;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes bounceHover {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.service-card h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgba(164,80,139,1) 0%, rgba(106,89,222,1) 50%, rgba(36,198,220,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-card p {
  font-size: 1.2em;
  font-style: bold;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(.9, .9, .9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(.97, .97, .97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}