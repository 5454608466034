.footer {
  background-color: #000;
  color: white;
  text-align: center;
  padding: 2px;
  position: relative;
  width: 100%;
  bottom: 0;
}

.gif-container {
  margin-bottom: 20px;
}

.hiddencat-gif {
  max-width: 100px;
  height: auto;
}

.copyright {
  margin-bottom: 10px;
}

