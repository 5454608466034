.contact-us-section {
  background-color: #000000; /* Black background for the section */
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.contact-us-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent; /* Black-purple color */
  color: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 900px;
  z-index: 4;
}

.contact-us-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.cat-gif {
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
}

.contact-us-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.contact-us-right h2 {
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #6a59de; /* Use highlight color */
}

.contact-form {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.2em;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 1em;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

.contact-button {
  background-color: #6a59de;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

.contact-button:hover {
  background-color: #a4508b; /* Lighter purple on hover */
  transform: scale(1.05);
}


