.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 12, 45, 0.7); /* Navy Blue with 70% opacity */
  color: #ffffff;
  z-index: 1000; /* Ensures the header stays above other elements */
}

.nav-left ul, .nav-right ul {
  list-style-type: none;
  margin: 0;
  padding: 10;
  display: flex;
}

.nav-left li, .nav-right li {
  margin: 0 15px;
}

.nav-left a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

.nav-left a:hover {
  text-decoration: underline;
}

.contact-button {
  padding: 10px 20px;
  background-color: #ffffff;
  color: #130c2d8;
  border: 2px solid #130c2d;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-button:hover {
  background-color: #130c2d;
  color: #ffffff;
}

.logo {
  height: 50px;
  padding-left: 95px;
}
