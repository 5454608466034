.training {
  padding: 50px 20px;
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text for contrast */
  text-align: center;
  z-index: 4;
}

.training-title {
  font-size: 4em; /* Very large font size */
 /* Thin font weight */
  color: #6a59de; /* Use the same highlight color */
  margin-bottom: 40px;
}

.training p {
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #ffffff; /* White text for contrast */
}

.courses {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.course-card {
  background-color: #6a59de; /* Match the button color */
  color: #ffffff; /* White text */
  padding: 20px;
  margin: 10px;
  width: 200px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 4;
  text-align: left;
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.course-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.course-card:hover::before {
  transform: translateX(100%);
}

.course-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.19), rgba(255, 255, 255, 0));
  transform: scale(0);
  transition: transform 0.5s ease;
}

.course-card:hover::after {
  transform: scale(1);
}
